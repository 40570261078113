.sidebar {
  flex: 1;
  height: calc(100vh - 70px);
  max-width: 20vw;
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width:7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(218, 214, 214);
  border-radius: 3.5px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(218, 214, 214); 
}
.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 1rem;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
@media screen and (max-width: 600px) {
  .sidebar {
    display: none;
  }
}
