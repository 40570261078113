.loading {
  height: calc(100vh - 70px);
  width: calc(100vw - 20vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user {
  flex: 4;
  padding: 20px;
  font-size: 1.2rem;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userContainer {
  display: flex;
  margin-top: 20px;
}

.userShow {
  flex: 1;
  padding: 20px;
  /* -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75); */
}

.userUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
  margin-bottom: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.userShowTop {
  display: flex;
  align-items: center;
}

.userShowImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userShowName {
  font-weight: 600;
  font-size: 1.7rem;
}

.userShowDomain {
  font-weight: 400;
  font-size: 1.1rem;
}

.userShowBottom {
  margin-top: 20px;
}

.userShowTitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  color: #444;
}

.userShowIcon {
  font-size: 1.3rem !important;
}

.userShowInfoTitle {
  margin-left: 10px;
}

.userUpdateTitle {
  font-size: 1.6rem;
  font-weight: 600;
}

.userUpdateForm {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 70vw;
  font-size: 1.4rem;
}

.userUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.userUpdateItem > label {
  margin-bottom: 5px;
  font-size: 1.2rem;
}
#user-update-label {
  font-size: 1.2rem;
}
#user-update-input {
  font-size: 1.2rem;
}
.userUpdateInput {
  border: none;
  width: 30vw;
  font-size: 1.2rem;
}

.userUpdateRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userUpdateUpload {
  display: flex;
  align-items: center;
}

.userUpdateImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.userUpdateIcon {
  cursor: pointer;
  font-size: 1.3rem;
}

.userUpdateButton {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: darkblue;
  color: white;
  font-weight: 600;
}

.profileEdit {
  padding: 10px;
  cursor: default;
}

.profile-action-btn {
  background-color: black;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
  position: fixed;
  bottom: 1%;
  right: 1%;
  cursor: pointer;
}
