.img-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-upload-top {
  display: flex;
  align-items: center;
}
.img-upload-bottom {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}
.img-preview {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.img-push-button {
  cursor: pointer;
  font-size: 1.6rem;
  margin-left: 1rem;
}
.img-push-button:hover {
  color: darkblue;
}
