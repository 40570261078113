.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-logo {
  margin-right: 1.1rem;
}
.top-text {
  font-weight: bold;
  font-size: 30px;
  color: #4169e1;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: center;
}
.topLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}
.top-logout {
  cursor: pointer;
  margin-left: 1rem;
}
.top-logout:hover {
  color: #4169e1;
}
.top-details {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid;
}
.top-details > div {
  margin-right: 1rem;
}
.top-name {
  font-size: 1.1rem;
  font-weight: 600;
}
.top-domain {
  font-size: 0.9rem;
}
@media screen and (max-width: 600px) {
  .top-text {
    display: none;
  }
  .top-user {
    display: none;
  }
}
