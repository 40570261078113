.home {
  flex: 4;
}

.homeWidgets {
  display: flex;
  margin: 20px;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:200,400,900&display=swap");

.wrapper {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
}

.wrapper .one {
  flex: 2;
  display: flex;
  align-items: center;
}

.wrapper .two {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.wrapper .one .heading {
  text-align: left;
  color: #000;
  margin-left: 10rem;
}

.wrapper .one .heading h6 {
  font-size: 2.5rem;
  font-weight: 300;
  /* line-height: 75px; */
}

.wrapper .one .heading h1 {
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

.wrapper .one .heading button {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 44px;
  border: none;
  outline: none;
  /* background-color: white !important; */
  background-color: #ffab3d;
  color: #fff;
  transition: 0.5s ease-in;
}

.wrapper .one .heading button a {
  color: #ffab3d;
  text-decoration: none;
}

.wrapper .one .heading button:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.wrapper .two .overflow img {
  width: 100%;
  height: auto;
}
.home-logo {
  width: 20vw;
  height: 20vh;
}
