.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
#input-label {
  font-size: 1.2rem;
}
.input-label {
  font-size: 1.2rem;
}
#input-form {
  font-size: 1.2rem;
}
.input-textfield {
  border: none;
  width: 30vw;
  font-size: 1.2rem;
}
@media screen and (max-width: 600px) {
  .input-textfield {
    width: 90vw;
  }
}
