.url-container {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.all-urls {
  height: calc(100vh - 120px);
}
.util-bar {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.url-heading {
  font-size: 1.4rem;
  font-weight: bold;
}
#url-createbtn {
  background-color: #4169e1;
  color: #fff;
}
.url-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
