@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,900&display=swap);
.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-logo {
  margin-right: 1.1rem;
}
.top-text {
  font-weight: bold;
  font-size: 30px;
  color: #4169e1;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: center;
}
.topLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}
.top-logout {
  cursor: pointer;
  margin-left: 1rem;
}
.top-logout:hover {
  color: #4169e1;
}
.top-details {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid;
}
.top-details > div {
  margin-right: 1rem;
}
.top-name {
  font-size: 1.1rem;
  font-weight: 600;
}
.top-domain {
  font-size: 0.9rem;
}
@media screen and (max-width: 600px) {
  .top-text {
    display: none;
  }
  .top-user {
    display: none;
  }
}

.container {
  display: flex;
  max-height: calc(100vh - 70px);
}

.link {
  text-decoration: none;
  color: inherit;
  margin: 0;
  padding: 0;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-section,
.right-section {
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: calc(100vh - 70px);
  align-items: center;
  justify-content: center;
}
.big {
  font-size: 3rem;
  font-family: "Raleway", sans-serif;
}
.small {
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
}
.front-img {
  width: 30vw;
}
.button-container {
  width: 30vw;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
#input-label {
  font-size: 1.2rem;
}
.input-label {
  font-size: 1.2rem;
}
#input-form {
  font-size: 1.2rem;
}
.input-textfield {
  border: none;
  width: 30vw;
  font-size: 1.2rem;
}
@media screen and (max-width: 600px) {
  .input-textfield {
    width: 90vw;
  }
}

.sidebar {
  flex: 1 1;
  height: calc(100vh - 70px);
  max-width: 20vw;
  background-color: rgb(251, 251, 255);
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width:7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(218, 214, 214);
  border-radius: 3.5px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(218, 214, 214); 
}
.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 1rem;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
@media screen and (max-width: 600px) {
  .sidebar {
    display: none;
  }
}

.home {
  flex: 4 1;
}

.homeWidgets {
  display: flex;
  margin: 20px;
}

.wrapper {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
}

.wrapper .one {
  flex: 2 1;
  display: flex;
  align-items: center;
}

.wrapper .two {
  flex: 1 1;
  position: relative;
  display: flex;
  align-items: center;
}

.wrapper .one .heading {
  text-align: left;
  color: #000;
  margin-left: 10rem;
}

.wrapper .one .heading h6 {
  font-size: 2.5rem;
  font-weight: 300;
  /* line-height: 75px; */
}

.wrapper .one .heading h1 {
  font-size: 4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

.wrapper .one .heading button {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 44px;
  border: none;
  outline: none;
  /* background-color: white !important; */
  background-color: #ffab3d;
  color: #fff;
  transition: 0.5s ease-in;
}

.wrapper .one .heading button a {
  color: #ffab3d;
  text-decoration: none;
}

.wrapper .one .heading button:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.wrapper .two .overflow img {
  width: 100%;
  height: auto;
}
.home-logo {
  width: 20vw;
  height: 20vh;
}

.userList {
  flex: 4 1;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  cursor: pointer;
  margin-right: 20px;
}

.userListDelete {
  color: red;
  cursor: pointer;
}
.userListView {
  cursor: pointer;
  margin-right: 20px;
}
.all-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-action-btn:hover {
  color: #4169e1;
}

.loading {
  height: calc(100vh - 70px);
  width: calc(100vw - 20vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user {
  flex: 4 1;
  padding: 20px;
  font-size: 1.2rem;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userContainer {
  display: flex;
  margin-top: 20px;
}

.userShow {
  flex: 1 1;
  padding: 20px;
  /* -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75); */
}

.userUpdate {
  flex: 2 1;
  padding: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
  margin-bottom: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.userShowTop {
  display: flex;
  align-items: center;
}

.userShowImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userShowName {
  font-weight: 600;
  font-size: 1.7rem;
}

.userShowDomain {
  font-weight: 400;
  font-size: 1.1rem;
}

.userShowBottom {
  margin-top: 20px;
}

.userShowTitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  color: #444;
}

.userShowIcon {
  font-size: 1.3rem !important;
}

.userShowInfoTitle {
  margin-left: 10px;
}

.userUpdateTitle {
  font-size: 1.6rem;
  font-weight: 600;
}

.userUpdateForm {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 70vw;
  font-size: 1.4rem;
}

.userUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.userUpdateItem > label {
  margin-bottom: 5px;
  font-size: 1.2rem;
}
#user-update-label {
  font-size: 1.2rem;
}
#user-update-input {
  font-size: 1.2rem;
}
.userUpdateInput {
  border: none;
  width: 30vw;
  font-size: 1.2rem;
}

.userUpdateRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userUpdateUpload {
  display: flex;
  align-items: center;
}

.userUpdateImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.userUpdateIcon {
  cursor: pointer;
  font-size: 1.3rem;
}

.userUpdateButton {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: darkblue;
  color: white;
  font-weight: 600;
}

.profileEdit {
  padding: 10px;
  cursor: default;
}

.profile-action-btn {
  background-color: black;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
  position: fixed;
  bottom: 1%;
  right: 1%;
  cursor: pointer;
}

.newUser {
  flex: 4 1;
}

.newUserForm {
  display: flex;
  flex-wrap: wrap;
}

.newUserItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.newUserItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.newUserItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.newUserGender > input {
  margin-top: 15px;
}

.newUserGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newUserSelect{
    height: 40px;
    border-radius: 5px;
}

.newUserButton{
    width: 200px;
    border: none;
    background-color: darkblue;
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}
.img-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-upload-top {
  display: flex;
  align-items: center;
}
.img-upload-bottom {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}
.img-preview {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.img-push-button {
  cursor: pointer;
  font-size: 1.6rem;
  margin-left: 1rem;
}
.img-push-button:hover {
  color: darkblue;
}

.eventList {
  flex: 4 1;
}

.eventListItem {
  display: flex;
  align-items: center;
}

.eventListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.eventListView {
  cursor: pointer;
  margin-right: 20px;
}
.eventListEdit {
  cursor: pointer;
  margin-right: 20px;
}

.eventListDelete {
  color: red;
  cursor: pointer;
}
.cert-link {
  color: #4169e1;
  cursor: pointer;
}
#menu-item-reg {
  font-size: 1rem;
}

.switch-container {
  display: flex;
  margin-top: 2rem;
  min-width: 10vw;
  justify-content: space-between;
}
.switch-label {
  font-size: 1.2rem;
}

.webinar-container {
  flex: 2 1;
  padding: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.webinar-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.light-background {
  background-color: #f5f5f5;
}
.webinar-card {
  background-color: #ffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  margin: 1rem 0;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#webinar-card-row {
  margin-bottom: 0.5rem;
}
#webinar-card-row-title {
  font-weight: 600;
}
#webinar-card-row-content {
  white-space: pre-line;
  color: #5e6472;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.webinar-reg-num.num {
  font-size: 4rem;
  font-weight: 600;
}
.webinar-reg-num.text {
  font-size: 1.5rem;
  color: #5e6472;
}
.webinar-chart {
  width: 60%;
}
.no-data-text {
  color: #5e6472;
  text-align: center;
}
.webinar-heading-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.webinar-heading-actions > div {
  margin-left: 1rem;
}
@media screen and (max-width: 600px) {
  .webinar-container {
    margin-left: 0;
  }
}

.url-container {
  flex: 2 1;
  padding: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.all-urls {
  height: calc(100vh - 120px);
}
.util-bar {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.url-heading {
  font-size: 1.4rem;
  font-weight: bold;
}
#url-createbtn {
  background-color: #4169e1;
  color: #fff;
}
.url-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

