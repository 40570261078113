@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-section,
.right-section {
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: calc(100vh - 70px);
  align-items: center;
  justify-content: center;
}
.big {
  font-size: 3rem;
  font-family: "Raleway", sans-serif;
}
.small {
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
}
.front-img {
  width: 30vw;
}
.button-container {
  width: 30vw;
}
