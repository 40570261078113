.container {
  display: flex;
  max-height: calc(100vh - 70px);
}

.link {
  text-decoration: none;
  color: inherit;
  margin: 0;
  padding: 0;
}
