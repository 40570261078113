.webinar-container {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.webinar-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.light-background {
  background-color: #f5f5f5;
}
.webinar-card {
  background-color: #ffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  margin: 1rem 0;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#webinar-card-row {
  margin-bottom: 0.5rem;
}
#webinar-card-row-title {
  font-weight: 600;
}
#webinar-card-row-content {
  white-space: pre-line;
  color: #5e6472;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.webinar-reg-num.num {
  font-size: 4rem;
  font-weight: 600;
}
.webinar-reg-num.text {
  font-size: 1.5rem;
  color: #5e6472;
}
.webinar-chart {
  width: 60%;
}
.no-data-text {
  color: #5e6472;
  text-align: center;
}
.webinar-heading-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.webinar-heading-actions > div {
  margin-left: 1rem;
}
@media screen and (max-width: 600px) {
  .webinar-container {
    margin-left: 0;
  }
}
