.eventList {
  flex: 4;
}

.eventListItem {
  display: flex;
  align-items: center;
}

.eventListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.eventListView {
  cursor: pointer;
  margin-right: 20px;
}
.eventListEdit {
  cursor: pointer;
  margin-right: 20px;
}

.eventListDelete {
  color: red;
  cursor: pointer;
}
.cert-link {
  color: #4169e1;
  cursor: pointer;
}
#menu-item-reg {
  font-size: 1rem;
}
